import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { storeActions } from '@/store/store-types';
import { ViewUtilitiesMixin } from '@/mixins/view-utilities/view-utilities';

export default {
  name: 'SettingAddEditDialog',
  mixins: [validationMixin, ViewUtilitiesMixin],
  props: ['actionItem', 'actionIndex', 'isMobile'],
  data: () => ({
    mobileModalHeight: null
  }),
  validations: {
    actionItem: {
      settingKey: {
        required
      },
      settingValue: {
        required
      }
    }
  },
  mounted() {
    this.mobileModalHeight = window.innerHeight * 0.5;
  },
  computed: {
    dialogTitle() {
      return this.actionIndex === -1 ? 'New Setting' : 'Edit Setting (' + this.actionItem.settingKey + ')';
    }
  },
  methods: {
    errors(key) {
      const errors = [];
      switch (key) {
        case 'settingKey':
        case 'settingValue':
          // if (!this.$v.actionItem[key].$dirty) return errors;
          !this.$v.actionItem[key].required && errors.push('Field is required');
          break;
        default:
          throw new Error(`key (${key}) not handled`);
      }
      return errors;
    },
    async save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.actionIndex > -1) {
          this.storeDispatchWithNotification({
            dispatchAction: storeActions.settings.update,
            data: this.actionItem,
            infoMessage: 'Item updated',
            successMessage: null,
            errorMessage: 'Error occurred trying to update the data'
          });
        } else {
          this.storeDispatchWithNotification({
            dispatchAction: storeActions.settings.create,
            data: this.actionItem,
            infoMessage: 'Item created',
            successMessage: null,
            errorMessage: 'Error occurred trying to create the data'
          });
        }
        this.close();
      }
    },
    close() {
      this.$v.$reset();
      this.$emit('close');
    }
  }
};
