import { mapState } from 'vuex';
import { storeActions } from '@/store/store-types';
import { ViewUtilitiesMixin } from '@/mixins/view-utilities/view-utilities';
import SettingAddEditDialog from '@/components/add-edit-dialogs/setting/setting.vue';

export default {
  mixins: [ViewUtilitiesMixin],
  components: {
    'setting-add-edit-dialog': SettingAddEditDialog
  },
  data: () => ({
    dialog: false,
    search: '',
    loading: false,
    isMobile: false,
    headers: [
      { text: 'Key', value: 'settingKey', align: 'left' },
      { text: 'Value', value: 'settingValue', align: 'left' },
      { text: 'Actions', value: 'action', sortable: false, align: 'left' }
    ],
    actionItem: {},
    actionIndex: -1
  }),
  computed: {
    ...mapState('settings', ['items'])
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  async mounted() {
    this.loading = true;
    await this.storeDispatchWithNotification({
      dispatchAction: storeActions.settings.list,
      data: null,
      infoMessage: null,
      successMessage: null,
      errorMessage: 'Error occurred trying to get the settings'
    });
    this.actionItem = this.defaultActionItem();
    this.loading = false;
  },

  methods: {
    defaultActionItem() {
      return {};
    },
    setActionItem(data) {
      this.actionItem = data;
      this.actionIndex = this.items.findIndex(item => item.settingKey === data.settingKey);
    },
    clearActionItem() {
      this.actionItem = this.defaultActionItem();
      this.actionIndex = -1;
    },
    editItem(data) {
      this.setActionItem(data);
      this.dialog = true;
    },
    openModal() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.clearActionItem();
    },
    deleteItemYesAction() {
      this.storeDispatchWithNotification({
        dispatchAction: storeActions.settings.delete,
        data: this.actionItem,
        infoMessage: 'Item deleted',
        successMessage: null,
        errorMessage: 'Error occurred trying to delete the data'
      });
      this.$snotify.remove();
      this.clearActionItem();
    },
    deleteItemNoAction() {
      this.$snotify.remove();
    },
    deleteItem(data) {
      this.setActionItem(data);
      this.$snotify.confirm('Are you sure you want to delete this item?', {
        buttons: [
          { text: 'Yes', action: this.deleteItemYesAction, bold: false },
          { text: 'No', action: this.deleteItemNoAction }
        ]
      });
    },
    handleResize() {
      if (window.innerWidth < 736) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }
  }
};
